/* eslint-disable no-undef */

require("prismjs/themes/prism.css")

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// TODO: find a better alternative to smooth scrolling than this
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    speed: 200,
  })
  // eslint-disable-next-line global-require
}

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash) {
    return false
  }

  return true
}

exports.onInitialClientRender = () => {
  // nothing to see here yet
}
